import { useAtom } from "jotai/react";
import { apiBackends } from "../../../apiBackends.ts";
import { WSimpleSelect } from "../../../components/dev/WSimpleSelect.tsx";
import { apiPrefixAtom, canSelectApiPrefix } from "../../api/createApiClient.ts";

export function ApiPrefixSelect() {
    if (!canSelectApiPrefix) {
        return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [prefix, setPrefix] = useAtom(apiPrefixAtom);

    const items = apiBackends.map(({ apiPrefix, label }) => ({ value: apiPrefix, label }));

    return (
        <WSimpleSelect
            placeholder="API prefix"
            value={prefix}
            items={items}
            onChange={(value) => {
                setPrefix(value);
            }}
        />
    );
}
