import { getDefaultStore } from "jotai/vanilla";
import { themeStyleElementId, updateCss } from "./cssLoader.ts";
import { themeCssAtom } from "./themeCss.store.ts";
import { getThemeProps_ONCE } from "./useTheme.ts";

let initialized = false;

/** Insert current theme's CSS into the DOM */
export function initTheme() {
    if (initialized) return;
    initialized = true;

    updateThemeCss();

    unsubscribeCssAtom ??= getDefaultStore().sub(themeCssAtom, () => {
        updateThemeCss();
    });
}

/** Update theme CSS (when theme or config changes) */
function updateThemeCss() {
    console.debug("Updating theme CSS...");
    updateCss(getDefaultStore().get(themeCssAtom), themeStyleElementId);

    const { favicon, icon, png } = getThemeProps_ONCE();

    let faviconEl = document.head.querySelector<HTMLLinkElement>("link[rel='icon'][type='image/svg+xml']");
    let faviconIco = document.head.querySelector<HTMLLinkElement>("link[rel='icon'][sizes='32x32']");
    let faviconPng = document.head.querySelector<HTMLLinkElement>("link[rel='apple-touch-icon']");

    if (favicon) {
        if (!faviconEl) {
            console.debug("Creating favicon element");
            faviconEl = document.createElement("link");
            faviconEl.rel = "icon";
            faviconEl.type = "image/svg+xml";
            document.head.appendChild(faviconEl);
        }

        console.debug("Updating favicon: %s", favicon);

        faviconEl.href = favicon;
    }

    if (icon) {
        if (!faviconIco) {
            faviconIco = document.createElement("link");
            faviconIco.rel = "icon";
            document.head.appendChild(faviconIco);
        }

        faviconIco.href = icon;
    }

    if (png) {
        if (!faviconPng) {
            faviconPng = document.createElement("link");
            faviconPng.rel = "apple-touch-icon";
            document.head.appendChild(faviconPng);
        }

        faviconPng.href = png;
    }
}

//#region Hot reload

let unsubscribeCssAtom: (() => void) | undefined;
import.meta.hot?.dispose(() => unsubscribeCssAtom?.());

export function __forceRefreshCss() {
    updateThemeCss();
}

import.meta.hot?.accept((newModule: unknown) => {
    console.debug("Hot reload theme init...");
    (newModule as typeof import("./init")).__forceRefreshCss();
});

//#endregion
