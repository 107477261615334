import type { paths } from "@warrenio/api-spec/spec.oats.gen";
import isNetworkError from "is-network-error";
import { atom } from "jotai/vanilla";
import createClient from "openapi-fetch";
import invariant from "tiny-invariant";
import { isMockApiEnabled } from "../../mock-api/msw/mswInit.ts";
import { isBuiltRelease, isTestEnvironment } from "../../utils/environment.ts";
import { fetchWithMock } from "../../utils/fetchClient.ts";
import { atomWithLocalStorage } from "../../utils/jotai/atomStorage.ts";
import type { ApiClient } from "./apiClient.ts";
import { ApiConnectionError, ApiContentTypeError } from "./ApiError.ts";
import { registerSchemaCheckMiddleware } from "./schemaCheck.ts";

/** NB: Without trailing slash */
export function getApiUrl(apiPrefix: string) {
    return `${window.location.origin}${apiPrefix}/v1`;
}

export function getWebsocketUrl(apiPrefix: string) {
    const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    return `${wsProtocol}://${window.location.host}${apiPrefix}/v1`;
}

export interface ApiParameters {
    apikey: string | undefined;
    apiPrefix: string;
}

export function createApiClient({ apikey, apiPrefix }: ApiParameters, preflightCheck?: () => Promise<void>): ApiClient {
    const client = createClient<paths>({
        fetch: async (request) => {
            invariant(!isTestEnvironment || isMockApiEnabled(), "Mock API must be enabled in test environment");

            await preflightCheck?.();

            try {
                return await fetchWithMock(request);
            } catch (e) {
                if (isNetworkError(e)) {
                    throw new ApiConnectionError(e, { requestUrl: request.url });
                }

                if (e instanceof DOMException && e.name === "AbortError") {
                    console.info("Fetch was aborted", e);
                } else {
                    console.error("Unknown error in fetch", e);
                }

                throw e;
            }
        },
        bodySerializer: (_body) => {
            throw new TypeError("Body serializer must be set, see eg. `urlEncodedBody`");
        },
        baseUrl: getApiUrl(apiPrefix),
        headers: apikey != null ? { apikey } : {},
    });
    registerSyntaxErrorMiddleware(client);
    registerSchemaCheckMiddleware(client);
    return client;
}

/**
 * Throw a custom error if we receive HTML instead of JSON (when we are *expecting* JSON).
 *
 * This is necessary because `openapi-fetch` does not properly support custom handling of parse failures - those throw
 * an early raw `SyntaxError` without a stack trace, which make error logs useless.
 */
function registerSyntaxErrorMiddleware(client: ApiClient) {
    client.use({
        async onResponse({ response, options, schemaPath }) {
            if (options.parseAs !== "json") {
                return;
            }

            const contentType = response.headers.get("content-type");
            if (!contentType?.includes("application/json")) {
                const text = await response.clone().text();
                // Detect HTML
                if (text.startsWith("<")) {
                    throw new ApiContentTypeError(text, contentType, {
                        requestUrl: response.url,
                        status: response.status,
                        schemaPath,
                    });
                }
            }
        },
    });
}

export const canSelectApiPrefix = !isBuiltRelease && !isTestEnvironment;
const defaultApiPrefix = "";

/** URL to prepend to all API requests */
export const apiPrefixAtom = canSelectApiPrefix
    ? atomWithLocalStorage("devApiPrefix2", defaultApiPrefix)
    : atom(defaultApiPrefix);

/** An API client for unauthenticated (logged out) requests */
export const unauthApiClientAtom = atom(
    (get): ApiClient =>
        createApiClient({
            apiPrefix: get(apiPrefixAtom),
            apikey: undefined,
        }),
);
