import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { privateApi, successResponse, tagPaths } from "../util.ts";
import { ManagedServiceBase, ManagedServiceEnum } from "./services.ts";

extendZodWithOpenApi(z);

const ManagedServiceBroken = ManagedServiceBase.extend({
    service: ManagedServiceEnum,
    properties: z.record(z.unknown()).optional(),
})
    .describe(
        "Admin version of `ManagedService` that can contain invalid data since it also includes legacy deleted services",
    )
    .openapi({ ref: "ManagedServiceBroken" });

const StatisticsResponse = z.array(ManagedServiceBroken).openapi({ ref: "StatisticsResponse" });

export const serviceAdminPaths = tagPaths("admin_services")({
    // TODO: This endpoint should not return properties in the backend for performance, probably
    "/user-resource/admin/service/packages": {
        get: {
            summary: `Get managed services statistics ${privateApi}`,
            parameters: [
                {
                    name: "status",
                    in: "query",
                    schema: { type: "array", items: { type: "string" } },
                    explode: true,
                },
                { name: "deleted_cutoff_date", in: "query" },
            ],
            responses: {
                ...successResponse(StatisticsResponse),
            },
        },
    },
});
