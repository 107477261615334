import type { Types } from "@honeybadger-io/core";
import type { Noticeable } from "@honeybadger-io/core/build/src/types";
import { Honeybadger } from "@honeybadger-io/react";
import { isObject } from "@warrenio/utils/isObject";
import { honeybadgerConfig } from "../../dev/honeybadgerConfig.ts";
import { addDevVars } from "../../dev/initDev.tsx";
import { isBuiltRelease } from "../../utils/environment.ts";
import { validateAndAssignErrorContext } from "./errorFields.tsx";

const isDevHost = location.hostname === "localhost" || /^\d+/.test(location.hostname);
const isEnabled = !isBuiltRelease || isDevHost;

/** NB: Do not use this export directly, instead create wrapper functions to decouple the error reporting provider from the app */
export const honeybadger_INTERNAL = isEnabled ? undefined : Honeybadger.configure(honeybadgerConfig);

function getCauses(notice: Types.Notice) {
    const causes = [];

    let current: Record<string, unknown> | undefined = notice;
    while (current) {
        causes.push(current);
        current = isObject(current.cause) ? current.cause : undefined;
    }

    // Reverse causes so the root overwrites the child fields
    causes.reverse();
    return causes;
}

honeybadger_INTERNAL?.beforeNotify((notice) => {
    if (!notice) {
        return;
    }

    // NB: Must copy object because the original seems to be shared
    const context = { ...notice.context };

    for (const cause of getCauses(notice)) {
        validateAndAssignErrorContext(cause, context);
    }

    notice.context = context;
});

export interface ErrorDetails {
    // https://stackoverflow.com/questions/65799316/why-cant-an-interface-be-assigned-to-recordstring-unknown
    context?: Record<string, any>;
    tags?: string[];
}

function detailsToNotice(details?: ErrorDetails) {
    if (details === undefined) {
        return undefined;
    }
    const { context, tags } = details;
    return {
        context,
        tags: tags?.join(", "),
    };
}

function internalNotifyError(error: Noticeable, details?: ErrorDetails) {
    const noticeDetails = detailsToNotice(details);
    if (honeybadger_INTERNAL) {
        honeybadger_INTERNAL.notify(error, noticeDetails);
    } else {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        console.debug("Error notice suppressed in development (%o): %s", noticeDetails, String(error));
    }
}

export function notifyError(error: unknown, details?: ErrorDetails) {
    const realError = error instanceof Error ? error : String(error);
    internalNotifyError(realError, details);
}

export function notifyErrorCustom(message: string, details?: ErrorDetails) {
    internalNotifyError(message, details);
}

export function addToErrorContext(context: Record<string, unknown>) {
    honeybadger_INTERNAL?.setContext(context);
}

addDevVars({ honeybadger_INTERNAL });
