import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";
import L from "./Login.module.css";

import { useState } from "react";
import { Input, type InputProps } from "react-aria-components";
import { Controller, useFormContext, type FieldValues, type Path } from "react-hook-form";
import { WButton } from "../../components/button/WButton.tsx";
import { FieldButtonGroup } from "../../components/forms/FieldButtonGroup.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { formPropsToAriaProps } from "../../components/forms/ariaController.tsx";
import { cn } from "../../utils/classNames.ts";

export function InputRow<T extends FieldValues>({
    name,
    label,
    isRequired = true,
    ...inputProps
}: { name: Path<T>; label: string; isRequired?: boolean } & InputProps) {
    const { type } = inputProps;

    const { control } = useFormContext<T>();

    const [passwordVisible, setPasswordVisible] = useState(false);

    const finalProps =
        type === "password"
            ? // Override passed-in type
              { ...inputProps, type: passwordVisible ? "text" : "password" }
            : inputProps;

    return (
        <Controller
            control={control}
            name={name}
            render={(p) => {
                const input = (
                    <Input
                        ref={p.field.ref}
                        id={name}
                        className={cn(FF.FormFieldInput, TF.Input, L.Input)}
                        placeholder={label}
                        {...finalProps}
                    />
                );

                return (
                    <WTextField className={FF.Block} wide aria-label={label} type="text" {...formPropsToAriaProps(p)}>
                        {type === "password" ? (
                            <FieldButtonGroup hasAbsoluteButton>
                                {input}

                                <WButton
                                    size="xs"
                                    ariaLabel={passwordVisible ? "Hide" : "Show"}
                                    variant="ghost"
                                    action={() => setPasswordVisible((v) => !v)}
                                    icon={cn(
                                        "size-0.875rem",
                                        passwordVisible ? "jp-nopreview-icon" : "jp-preview-icon",
                                    )}
                                />
                            </FieldButtonGroup>
                        ) : (
                            input
                        )}
                    </WTextField>
                );
            }}
        />
    );
}
