import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { errorResponse, jsonBody, successResponse, tagPaths } from "../util.ts";
import * as params from "./params.ts";
import { PricingList, ScheduledPricingBody } from "./pricing.ts";

extendZodWithOpenApi(z);

export const PricingHistory = z.record(PricingList).openapi({ ref: "PricingHistory" });

export const pricingAdminPaths = tagPaths("admin_pricing")({
    "/pricing/list": {
        get: {
            summary: "Get pricing history",
            responses: {
                ...successResponse(PricingHistory),
            },
        },
    },
    "/pricing/list/{id}": {
        put: {
            description: "Add scheduled prices",
            parameters: [params.id],
            requestBody: jsonBody(ScheduledPricingBody),
            responses: {
                ...successResponse(PricingList, "Success: Updated prices"),
                ...errorResponse("Failed to update prices"),
            },
        },
        delete: {
            description: "Delete scheduled prices",
            parameters: [params.id],
            responses: { ...successResponse(z.boolean()) },
        },
    },
});
