import { z, type ZodRawShape } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import { errorResponse, jsonBody, successResponse, tagPaths } from "../util.ts";
import { created_at, uuid } from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

//#region Host
const AdminHostFields = {
    allocated_vcpu: z.number(),
    allocated_vmem: z.number(),
    cpu_info: z
        .object({
            nproc: z.number(),
            cpu_model: z.string(),
            cpu_model_name: z.string(),
            libvirt_cpu_model: z.string(),
        })
        .optional(),
    created_at: created_at.optional(),
    host_pool_id: z.number(),
    id: z.number(),
    ip: z.string(), //.ip(),
    is_accepting_workloads: z.boolean(),
    is_deleted: z.boolean(),
    is_maintenance: z.boolean(),
    is_offline: z.boolean(),
    name: z.string(),
    os: z.string(),
    pool_cpu_model: z.string(),
    pool_name: z.string(),
    pool_uuid: uuid,
    status: z.number(),
    storage_pool_uuid: uuid.optional(),
    total_cpus: z.number(),
    total_mem_gb: z.number(),
    updated_at: created_at,
    usable_vcpu_amount: z.number(),
    usable_vmem_amount: z.number(),
    uuid,
    vcpu_free: z.number(),
    vmem_free: z.number(),
} as const satisfies ZodRawShape;

export const AdminHost = z.object(AdminHostFields).openapi({ ref: "AdminHost" });

export const AdminHostUpdateBody = z
    .object({
        uuid,
        is_accepting_workloads: z.boolean(),
        is_maintenance: z.boolean(),
        is_offline: z.boolean(),
    })
    .openapi({ ref: "AdminHostUpdateBody" });

export const AdminHostEditBody = z
    .object({
        uuid,
        host_pool_uuid: uuid.optional(),
        os: z.string().optional(),
    })
    .openapi({ ref: "AdminHostEditBody" });
//#endregion

export const AdminHostResponse = z.array(AdminHost);

export const hostPaths = tagPaths("admin_hosts")({
    "/{location}/base-operator/host/list": {
        get: {
            summary: "List Hosts",
            parameters: [params.location],
            responses: {
                ...successResponse(AdminHostResponse),
                ...errorResponse(`Failed to list host pools from ${params.location}`),
            },
        },
    },

    "/{location}/base-operator/admin/host": {
        patch: {
            summary: "Update host",
            parameters: [params.location],
            requestBody: jsonBody(AdminHostEditBody),
            responses: {
                ...successResponse(AdminHost),
            },
        },
    },

    "/{location}/base-operator/admin/host_flags": {
        put: {
            summary: "Change host status",
            parameters: [params.location],
            requestBody: jsonBody(AdminHostUpdateBody),
            responses: {
                ...successResponse(AdminHost),
            },
        },
    },
});
