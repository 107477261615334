import { z } from "zod";
import { jsonBody, privateApi, successResponse, tagPaths } from "../util.ts";

const pathExample = "/ui/key";

const path = z.string().openapi({ example: pathExample });

const path_param = z.string().openapi({ param: { name: "path", in: "query" }, example: pathExample });

const ConfigSection = z
    .object({
        section_key: z.string(),
        data: z.unknown(),
    })
    .openapi({ ref: "ConfigSection" });

const ConfigGetResponse = z
    .union([
        z.string(),
        // When `recurse` is `true`
        z.array(ConfigSection),
        z.null(),
    ])
    .openapi({ ref: "ConfigGetResponse" });

const ConfigSaveBody = z
    .object({
        path,
        data: z.string(),
    })
    .openapi({ ref: "ConfigSaveBody" });

export const configAdminPaths = tagPaths("admin_settings")({
    "/configuration/admin/value": {
        get: {
            summary: `Get Configuration ${privateApi}`,
            parameters: [path_param, { name: "recurse", in: "query" }],
            responses: {
                ...successResponse(ConfigGetResponse),
            },
        },
        post: {
            summary: `Save Configuration ${privateApi}`,
            requestBody: jsonBody(ConfigSaveBody),
            responses: {
                ...successResponse(z.boolean()),
            },
        },
        delete: {
            summary: `Delete Configuration ${privateApi}`,
            parameters: [path_param],
            responses: {
                ...successResponse(z.boolean()),
            },
        },
    },
});
