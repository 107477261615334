import { autoDarkColors } from "../../modules/theme/color/darkModeColor";
import type { ThemeProps, ThemeVarGetters, ThemeVariant } from "../../modules/theme/types";

import png from "./apple-touch-icon.png?url";
import icon from "./favicon.ico?url";
import favicon from "./favicon.svg?url";
import loginImage from "./login-bg.png";
import loginLogo from "./logo-dark.svg";
import logoDark from "./logo-dark.svg?url";
import logo from "./logo.svg?url";

export function makeTheme(variant: ThemeVariant) {
    const { isDark } = variant;

    const baseColors: ThemeVarGetters = autoDarkColors({
        "color-primary": "#0c3e98",
        "color-secondary": "#e5e5e5",
    });

    return {
        ...baseColors,

        "logo-image": isDark ? `url("${logoDark}")` : `url("${logo}")`,

        "header-logo-width": "108px",
        "header-logo-height": "16px",

        "sidebar-item-icon-color": "#ce051d",

        "sidenav-item-icon-color": "#ce051d",
        "sidenav-item-bg-color": "transparent",
        "sidenav-item-text-color": "var(--color-text)",
        "sidenav-item-before-width": "2px",
        "sidenav-item-hover-bg-color": "var(--color-primary)",
        "sidenav-item-hover-icon-color": "#ce051d",
        "sidenav-item-hover-text-color": "#FFFFFF",
        "sidenav-item-active-bg-color": "var(--color-primary)",
        "sidenav-item-active-icon-color": "#ce051d",
        "sidenav-item-active-text-color": "#FFFFFF",
        "sidenav-item-active-before-bg-color": "#ce051d",

        //#region Login
        "login-body-bg-color": "#ffffff",
        "login-body-bg-image": "",
        "login-body-bg-size": "cover",
        "login-body-text-color": "var(--color-text)",
        "login-justify-content": "space-between",
        "login-align-items": "stretch",

        "login-intro-display": "flex",
        "login-intro-bg-image": `url("${loginImage}")`,
        "login-intro-bg-size": "cover",
        "login-intro-bg-position": "0 100%",
        "login-intro-text-align": "center",
        "login-intro-flex-direction": "column",
        "login-intro-justify-content": "center",
        "login-intro-align-items": "center",
        "login-intro-padding": "80px",
        "login-title-font-size": "36px",
        "login-title-text-color": "",
        "login-title-font-weight": 300,
        "login-title-line-height": 1,
        "login-title-max-width": "450px",
        "login-title-padding": "20px 0 6px 0",
        "login-subtitle-font-size": "21px",
        "login-subtitle-max-width": "",

        "login-form-title-font-size": "18px",
        "login-form-bg-color": "#ffffff",
        "login-form-text-color": "var(--color-text)",
        "login-form-input-border-color": "#F2F2F2",
        "login-form-input-bg-color": "#F2F2F2",
        "login-form-button-border-radius": "24px",
        "login-form-or-text-color": "rgb(221, 231, 238)",
        "login-form-or-border-color": "rgb(221, 231, 238)",
        "login-form-google-bg-color": "#007bf5",
        "login-form-more-text-color": "var(--color-primary)",
        "login-form-more-bg-color": "transparent",
        "login-form-more-border-color": "var(--color-primary)",
        //#endregion
    } satisfies ThemeVarGetters;
}

export const props: ThemeProps = {
    favicon,
    icon,
    png,
    loginLogoImg: {
        src: loginLogo,
        style: {
            maxWidth: "200px",
        },
    },
};
