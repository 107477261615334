import "./chat.css";

import Intercom, { show } from "@intercom/messenger-js-sdk";
import type {
    BotikaConfig,
    IntercomConfig,
    LeadBoosterConfig,
    OncehubConfig,
    QiscusConfig,
} from "@warrenio/api-spec/spec.oats.gen";
import { makeOnce } from "@warrenio/utils/makeOnce";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useAtomValue } from "jotai/react";
import { atom } from "jotai/vanilla";
import { useEffect } from "react";
import { chatConfigAtom, configAtom } from "../../config.ts";
import { cleanTimeout } from "../../utils/cleanTimeout.ts";
import { loadScript } from "../../utils/loadScript.ts";
import { showWarn } from "../error/errorStream.ts";
import { getThemeVar_ONCE } from "../theme/useTheme.ts";

export interface ChatProvider {
    load(): Promise<void>;
    /** Show the messenger interface */
    show(): void;
}

/* eslint-disable @typescript-eslint/no-namespace */

//
// CONVENTIONS:
//
// - All the factory functions are wrapped in `makeOnce` since configuration of the chat providers should only happen once. (even if eg. the config changes due to hot reload)
// - The `load` method is also wrapper so that the chat provider is only loaded once (and the promise can be awaited without checking)
//

//#region Qiscus

declare global {
    class Qismo {
        constructor(key: string, options: object);
    }
}

const qiscusFactory = makeOnce(
    ({ qiscus_channel_id, qiscus_client_key }: QiscusConfig): ChatProvider => ({
        load: makeOnce(async () => {
            await loadScript("https://s3-ap-southeast-1.amazonaws.com/qiscus-sdk/public/qismo/qismo-v4.js");

            const _qismo = new Qismo(qiscus_client_key, {
                options: {
                    channel_id: qiscus_channel_id,
                    extra_fields: [],
                },
            });
        }),
        show() {
            const btn = document.querySelector<HTMLElement>(".qcw-trigger-btn");
            btn?.click();
        },
    }),
);

//#endregion

//#region Botika
declare global {
    namespace BotikaChat {
        function init(options: object): unknown;
    }
}

const botikaFactory = makeOnce(
    ({ botika_client_key }: BotikaConfig, primaryColor: string, logoImage: string, siteName: string): ChatProvider => ({
        load: makeOnce(async () => {
            await loadScript("//chat.botika.online/client/assets/js/botika.widget.js");

            BotikaChat.init({
                client: botika_client_key,
                widget: {
                    theme: "custom",
                    "theme-color": primaryColor,
                    "bubble-color": primaryColor,
                    "description-color": "#ffffff",
                    title: siteName,
                    description: "Customer support",
                    caption: "Need help?",
                    greeting: true,
                    logo: logoImage,
                    history: true,
                    "greeting-message": "Hello",
                    "greeting-button": "Start",
                    autocomplete: "",
                    registerUser: { name: true, email: true, phone: false },
                    startMessage: "",
                    clientCustom: "",
                    buttonPayload: true,
                    voice: { active: false, disabled: true },
                },
            });
        }),
        show() {
            warnNotImplemented();
        },
    }),
);
//#endregion

//#region Oncehub

const oncehubFactory = makeOnce(
    ({ oncehub_website_id, oncehub_bot_id }: OncehubConfig): ChatProvider => ({
        load: makeOnce(async () => {
            await loadScript("https://cdn.oncehub.com/co/widget.js", (script) => {
                script.id = "co-index";
                script.dataset.coParams = `website_id=${oncehub_website_id}&bot_type=2`;
            });

            const link = document.createElement("a");
            link.id = "oncechat-link";
            link.href = "#";
            link.dataset.coBot = oncehub_bot_id;
            link.setAttribute("onclick", "document.getElementById('oncechat-link').style.display='none'");
            document.body.appendChild(link);
        }),
        show() {
            const btn = document.querySelector<HTMLElement>("#oncechat-link");
            btn?.click();
        },
    }),
);

//#endregion

//#region LeadBooster

declare global {
    interface Window {
        pipedriveLeadboosterConfig?: {
            base: string;
            companyId: string;
            playbookUuid: string;
            version: number;
        };
        LeadBooster?: {
            q: unknown[];
            on(n: unknown, h: unknown): void;
            trigger(n: "open" | "close"): void;
        };
    }
}

const leadBoosterFactory = makeOnce(
    ({ company_id, playbook_uuid }: LeadBoosterConfig): ChatProvider => ({
        load: makeOnce(async () => {
            // DOCS: https://pipedrive.readme.io/docs/leadbooster-browser-api

            if (window.LeadBooster) {
                console.warn("LeadBooster already exists");
            } else {
                // Dummy implementation while loading
                window.LeadBooster = {
                    q: [],
                    on: function (n, h) {
                        this.q.push({ t: "o", n: n, h: h });
                    },
                    trigger: function (n) {
                        this.q.push({ t: "t", n: n });
                    },
                };
            }

            window.pipedriveLeadboosterConfig = {
                base: "leadbooster-chat.pipedrive.com",
                companyId: company_id,
                playbookUuid: playbook_uuid,
                version: 2,
            };

            await loadScript("https://leadbooster-chat.pipedrive.com/assets/loader.js");
        }),
        show() {
            window.LeadBooster?.trigger("open");
        },
    }),
);

//#endregion

//#region Intercom
const intercomFactory = makeOnce(
    ({ intercom_app_id }: IntercomConfig, primaryColor: string): ChatProvider => ({
        load: makeOnce(async () => {
            // DOCS: https://developers.intercom.com/installing-intercom/web/methods

            // TODO: Pass user data to Intercom

            Intercom({
                app_id: intercom_app_id,
                alignment: "left",
                action_color: primaryColor,
                background_color: primaryColor,
            });
        }),
        show() {
            show();
        },
    }),
);
//#endregion

export const chatProviderAtom = atom<ChatProvider | undefined>((get) => {
    const chatConfig = get(chatConfigAtom);
    if (!chatConfig || !("type" in chatConfig)) {
        return undefined;
    }

    const { siteName } = get(configAtom);
    const primaryColor = getThemeVar_ONCE("color-primary");
    const logoImage = getThemeVar_ONCE("logo-image");

    const { type } = chatConfig;
    switch (type) {
        case "intercom": {
            return intercomFactory(chatConfig, primaryColor);
        }
        case "qiscus": {
            return qiscusFactory(chatConfig);
        }
        case "botika": {
            return botikaFactory(chatConfig, primaryColor, logoImage, siteName);
        }
        case "oncehub": {
            return oncehubFactory(chatConfig);
        }
        case "leadbooster": {
            return leadBoosterFactory(chatConfig);
        }
        default: {
            showWarn("Unsupported chat provider type", type);
            return undefined;
        }
    }
});

const CHAT_INIT_DELAY_MS = 1500;

export function useLoadChatProvider(enabled = true) {
    const chatProvider = useAtomValue(chatProviderAtom);
    useEffect(
        () =>
            cleanTimeout(() => {
                if (enabled && chatProvider) {
                    discardPromise(chatProvider.load());
                }
            }, CHAT_INIT_DELAY_MS),
        [chatProvider, enabled],
    );
}

//#region Utils

/** @deprecated Implement me */
function warnNotImplemented() {
    showWarn("Chat method not implemented");
}

//#endregion
